import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'reporte',
    component: () => import('../views/ReporteView.vue')
  },
  // {
  //   path: '/perfil',
  //   name: 'perfil',
  //   component: () => import('../views/PerfilView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
